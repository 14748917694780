<template>
    <div>
        <template>
            <div id="breadcrumb-alignment" class="mb-1">
                <div class="d-flex justify-content-start breadcrumb-wrapper">
                    <b-breadcrumb :items="breadCrumb()" />
                </div>
            </div>
        </template>
        <!--filter selection section-->
        <b-card>
            <b-row>
                <b-col md="12">
                    <b-button style="width: 100%;" @click="filter = !filter" variant="warning">
                        Filter
                    </b-button>
                </b-col>
            </b-row>
            <b-form v-show="filter">
                <b-row>
                    <b-col md="6">
                        <b-form-group label="Item Name">
                            <v-select v-model="filter_item_name" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="itemNames" :options="itemNames" placeholder="Select Item" @input="filterTable" />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group label="Status">
                            <v-select v-model="filter_item_status" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="filter_user_status" :options="itemStatus" placeholder="Select Status"
                                @input="filterTable" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group label="Product Code">
                            <v-select v-model="filter_item_product_code"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="itemProductCodes"
                                :options="itemProductCodes" placeholder="Select Product Code" @input="filterTable" />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group label="Halal">
                            <v-select v-model="filter_halal" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="halal" :options="['Yes', 'No']" placeholder="Select Halal Status"
                                @input="filterTable" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="12" class="d-flex align-items-center justify-content-center justify-content-sm-center">
                        <b-button variant="warning" @click="resetFilter"
                            v-if="filter_item_name != '' || filter_item_status != 'Active' || filter_item_product_code != '' || filter_halal != ''">
                            <span>Reset Filter</span>
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </b-card>

        <b-card no-body class="mb-0">
            <div class="m-2"> <!--table top bar section-->
                <b-row>
                    <b-col cols="12" md="2" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <b-form-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50"
                            @input="filterTable" style="text-align: center;" />
                        <label>entries</label>
                    </b-col>
                    <b-col cols="12" md="8">
                        <div class="d-flex align-items-center justify-content-center mt-1">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search"
                                @input="filterTable" />
                        </div>
                    </b-col>
                    <b-col cols="12" md="2">
                        <div class="d-flex align-items-cebter justify-content-center">
                            <b-button variant="warning" class="mt-1" @click="openModal('Add', null)">
                                <span class="text-nowrap">Create New Item</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <b-table sticky-header responsive show-empty ref="refUserListTable" class="position-relative smallTable"
                primary-key="id" empty-text="No matching records found" :fields="tableColumns" :sort-by.sync="sortBy"
                :items="itemDocs" :sort-desc.sync="isSortDirDesc">
                <template #cell(actions)="items">
                    <div>
                        <span>
                            <b-dropdown variant="link" no-caret>
                                <template v-slot:button-content>
                                    <feather-icon icon="MoreVerticalIcon" size="16"
                                        class="text-body align-middle mr-25" />
                                </template>
                                <b-dropdown-item @click="openModal('View', items.item)">
                                    <feather-icon icon="SearchIcon" class="mr-50" />
                                    <span>View Item</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="openModal('Edit', items.item)">
                                    <feather-icon icon="SearchIcon" class="mr-50" />
                                    <span>Edit Item</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="deleteItem(items.item._id, items.item.product_code)">
                                    <feather-icon icon="TrashIcon" class="mr-50" />
                                    <span>Delete Item</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </span>
                    </div>
                </template>
                <template #cell(product_code)="items">
                    <p class="">{{ items.item.product_code | capitalize }}</p>
                </template>
                <template #cell(item_name)="items">
                    <p class="">{{ items.item.item_name }}</p>
                </template>
                <template #cell(price)="items">
                    <p class="">{{ items.item.price }}</p>
                </template>
                <template #cell(item_photo)="items">
                    <!-- <p class="">{{ items.item.item_photo }}</p> -->
                    <img :src="items.item.item_photo" alt="Item Photo" id="item_photo" />
                </template>
            </b-table>

            <div class="mx-2 mb-2"> <!--table footer-->
                <b-row>
                    <b-col cols="12" sm="4"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="4"
                        class="d-flex align-items-center justify-content-center justify-content-sm-center">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item"
                            @input="filterTable">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                    <b-col cols="12" sm="4"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <!-- View Item Modal -->
        <b-modal id="view-item" centered ref="view-item" header-bg-variant="warning" @hidden="ResetData" :title="title"
            no-close-on-backdrop hide-footer size="lg">
            <b-alert variant="danger" show>
                <div class="alert-body"> {{ error_message }} </div>
            </b-alert>
            <b-form>
                <b-row>
                    <b-col md="12">
                        <b-form-group label="Product Code">
                            <b-form-input type="text" v-model="form.product_code" disabled />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Item Name">
                            <b-form-input v-model="form.item_name" disabled></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Price">
                            <b-form-input disabled type="text" v-model="form.price" />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Halal">
                            <b-form-input v-model="form.halal" placeholder="Not Specified" disabled></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Item Photo">
                            <span v-show="image_err_view" class="text-danger">Original Image is borken</span>
                            <div v-show="!image_err_view">
                                <img :src="form.item_photo" alt="Item Photo" class="item_photo view"
                                    @error="imageLoadError('view')" />
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </b-modal>

        <!-- Edit Item Modal -->
        <b-modal id="edit-item" centered ref="edit-item" ok-only header-bg-variant="warning" ok-variant="warning"
            footer-class="justify-content-center" ok-title="Submit" @ok="updateForm(edit_form.id)" :title="title"
            no-close-on-backdrop size="lg">
            <b-alert variant="danger" show>
                <div class="alert-body"> {{ error_message }} </div>
            </b-alert>
            <b-form>
                <b-row>
                    <b-col md="12">
                        <b-form-group label="Product Code">
                            <b-form-input type="text" v-model="edit_form.product_code" disabled />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Item Name">
                            <b-form-input v-model="edit_form.item_name"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Price">
                            <b-form-input type="number" v-model="edit_form.price" />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Halal">
                            <v-select v-model="edit_form.halal" label="halal" :options="['Yes', 'No']"
                                placeholder="Not Specified <Please choose (Yes/No)>" />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Item Photo">
                            <span v-show="image_err" class="text-danger">Original Image is broken<br></span>
                            <b-button v-show="image_err" @click="showImageUpload('edit')">Upload Image</b-button>
                            <div class="img_upload" v-show="!image_err">
                                <img :src="edit_form.item_photo" class="edit" alt="Item Photo"
                                    @error="imageLoadError('edit')" />
                                <b-button @click="showImageUpload('edit')" class="edit-btn">Upload Image</b-button>
                                <input type="file" accept="image/png, image/jpg, image/jpeg" ref="edit_item_photo"
                                    hidden @change="handleEditItemPhoto">
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </b-modal>

        <!-- Add Item Modal -->
        <b-modal id="add-item" centered ref="add-item" ok-only header-bg-variant="warning" ok-variant="warning"
            footer-class="justify-content-center" ok-title="Submit" @ok="addItem()" :title="title" no-close-on-backdrop
            size="lg">
            <b-alert variant="danger" show>
                <div class="alert-body"> {{ error_message }} </div>
            </b-alert>
            <b-form>
                <b-row>
                    <b-col md="12">
                        <b-form-group label="Product Code" class="required">
                            <b-form-input type="text" v-model="add_form.product_code" />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Item Name" class="required">
                            <b-form-input v-model="add_form.item_name"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Price">
                            <b-form-input type="number" v-model="add_form.price" />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Halal" class="required">
                            <v-select v-model="add_form.halal" label="halal" :options="['Yes', 'No']"
                                placeholder="Select Halal Status" />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Item Photo">
                            <b-button @click="showImageUpload('add')" v-show="!add_form.item_photo">Upload
                                Image</b-button>
                            <div class="img_upload" v-show="add_form.item_photo">
                                <img :src="add_form.item_photo" class="edit" alt="Item Photo" />
                                <b-button @click="showImageUpload('add')" class="edit-btn">Upload Image</b-button>
                                <input type="file" accept="image/png, image/jpg, image/jpeg" ref="add_item_photo" hidden
                                    @change="handleAddItemPhoto">
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </b-modal>

    </div>
</template>

<script>
import {
    BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BFormDatepicker, BFormSelect, BFormSelectOption, BModal, BBreadcrumb, BFormGroup, BForm, BInputGroupPrepend, BInputGroup, BInputGroupAppend, BAlert, BFormFile, BImg
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API, GET_API } from "../../../store/actions.type"
import Bus from "../../../event-bus";
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import moment from "moment-timezone";
import axios from 'axios';

export default {
    data() {
        return {
            webUrl: process.env.VUE_APP_SERVER_URL,
            items: [],
            filter_item_status: '',
            itemStatus: [],
            itemNames: [],
            itemProductCodes: [],
            error_message: '',
            filter_item_name: '',
            filter_item_product_code: '',
            filter_halal: '',
            filter: false,
            image_err: false,
            image_err_view: false,
            //#region table content
            tableColumns: [
                { key: 'actions', label: 'Actions', sortable: false, thStyle: { width: '10%' } },
                { key: 'product_code', label: 'Product Code', sortable: true, thStyle: { width: '15%' } },
                { key: 'item_name', label: 'Item Name', sortable: true, thStyle: { width: '25%' } },
                { key: 'price', label: 'Price', sortable: true, thStyle: { width: '10%' } },
                { key: 'item_photo', label: 'Item Photo', sortable: false, thStyle: { width: '30%' } },
            ],
            perPageOptions: [20, 30, 50, 100],
            perPage: 20,
            sortBy: 'id',
            searchQuery: '',
            isSortDirDesc: true,
            totalRecords: 0,
            currentPage: 1,
            from: null,
            to: null,
            //#endregion

            //#region view item form
            title: null,
            form: {
                product_code: '',
                item_name: '',
                price: '',
                halal: '',
                item_photo: '',
            },
            //#endregion

            //#region edit item form
            edit_form: {
                id: '',
                product_code: '',
                item_name: '',
                price: '',
                halal: '',
                item_photo: '',
                file_name: '',
            },
            //#endregion

            //#region add item form
            add_form: {
                product_code: null,
                item_name: null,
                price: '',
                halal: '',
                item_photo: '',
                file_name: '',
            },
            //#endregion

            // showDismissibleAlert: false, 
        }
    },
    methods: {
        async itemDocs() {
            const filter_item_name = (this.filter_item_name == null || this.filter_item_name == '') ? 'All' : this.filter_item_name;
            const filter_item_product_code = (this.filter_item_product_code == null || this.filter_item_product_code == '') ? 'All' : this.filter_item_product_code;
            const filter_halal = (this.filter_halal == null) ? '' : this.filter_halal;
            const filter_item_status = (this.filter_item_status == null || this.filter_item_status == '') ? 'Active' : this.filter_item_status;
            await this.$store.dispatch(POST_API, {
                data: {
                    page: this.currentPage,
                    keyword: this.searchQuery,
                    rowsPerPage: this.perPage,
                    sortBy: this.sortBy,
                    sortDirection: this.isSortDirDesc,
                    account_type: this.$store.getters.currentUser.account_type,
                    site: this.$store.getters.currentUser.site,
                    filter_item_status: filter_item_status,
                    filter_item_name: filter_item_name,
                    filter_item_product_code: filter_item_product_code,
                    filter_halal: filter_halal,
                },
                api: '/api/get-item-docs'
            });
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showError("Can't retrieve Items");
                window.scrollTo(0, 0);
            } else {
                this.error_message = '';
                this.items = this.$store.getters.getResults.data.docs;
                this.totalRecords = this.$store.getters.getResults.data.total;
                this.perPage = this.$store.getters.getResults.data.limit;
                this.from = this.$store.getters.getResults.data.from;
                this.to = this.$store.getters.getResults.data.to;

                return this.items;
            }
        },
        async getItems() {
            await this.$store.dispatch(POST_API, {
                data: {
                    site: this.$store.getters.currentUser.site,
                },
                api: '/api/get-item-list'
            });
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showError("Can't retrieve Items");
                window.scrollTo(0, 0);
            } else {
                this.error_message = '';
                this.itemNames = this.$store.getters.getResults.data.itemNames;
                this.itemStatus = this.$store.getters.getResults.data.status;
                this.itemProductCodes = this.$store.getters.getResults.data.itemProductCodes;
                this.itemStatus.push('With No Image');
                this.itemNames.unshift('All');
                this.itemProductCodes.unshift('All');
            }
        },
        showImageUpload(type) {
            if (type == 'edit') {
                this.$refs.edit_item_photo.click();
            } else {
                this.$refs.add_item_photo.click();
            }
        },
        handleEditItemPhoto(event) {
            const imgReader = new FileReader();
            const img = event.target;
            if (img.files && img.files[0]) {
                this.edit_form.file_name = img.files[0].name;
                imgReader.readAsDataURL(img.files[0]);
                var image = img.files[0];
                if (image.size > 2097152) {

                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: 'Maximum 2 MB files allowed to be upload.',
                        showConfirmButton: false,
                        timer: 1500
                    })

                } else {
                    imgReader.onload = (e) => {
                        this.edit_form.item_photo = e.target.result;
                        this.image_err = false;
                    }
                }
            }
            this.$refs.edit_item_photo.value = null;
        },
        handleAddItemPhoto(event) {
            const imgReader = new FileReader();
            const img = event.target;
            if (img.files && img.files[0]) {
                this.add_form.file_name = img.files[0].name;
                imgReader.readAsDataURL(img.files[0]);
                var image = img.files[0];
                if (image.size > 2097152) {

                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: 'Maximum 2 MB files allowed to be upload.',
                        showConfirmButton: false,
                        timer: 1500
                    })

                } else {
                    imgReader.onload = (e) => {
                        this.add_form.item_photo = e.target.result;
                    }
                }
            }
            this.$refs.add_item_photo.value = null;
        },
        imageLoadError(type) {
            if (type == 'edit') {
                this.image_err = true;
            } else {
                this.image_err_view = true;
            }
        },
        async openModal(type, item) {
            if (type === 'View') {
                this.title = (item.product_code != '') ? 'View Item ' + item.product_code : 'View Item ' + item.item_name;
                this.form.product_code = item.product_code;
                this.form.item_name = item.item_name;
                this.form.price = item.price;
                this.form.halal = item.halal;
                this.form.item_photo = item.item_photo;
                this.image_err_view = false;
                this.$refs['view-item'].show();
            } else if (type === 'Edit') {
                if (this.error_message == null || this.error_message == '') {
                    this.ResetEditData();
                }
                this.title = (item.product_code != '') ? 'Edit Item ' + item.product_code : 'Edit Item ' + item.item_name;
                this.edit_form.product_code = item.product_code;
                this.edit_form.item_name = item.item_name;
                this.edit_form.price = item.price;
                this.edit_form.halal = item.halal;
                this.edit_form.item_photo = item.item_photo;
                this.edit_form.id = item._id;

                this.$refs['edit-item'].show();
            } else if (type === 'Add') {
                this.title = 'Create New Item';

                if (Object.values(this.add_form).every(x => x === null || x === '')) //Remove error message if user has not inputted any data in Create function
                {
                    this.error_message = '';
                }
                if (this.error_message == null || this.error_message == "") //Prevent resetting of data if there is error
                {
                    this.ResetAddData();
                }
                this.$refs['add-item'].show();
            }
        },
        async updateForm(editId) {
            return this.$store.dispatch(POST_API, {
                data: {
                    id: editId,
                    item_name: this.edit_form.item_name,
                    price: this.edit_form.price,
                    item_photo: this.edit_form.item_photo,
                    halal: this.edit_form.halal,
                    file_name: this.edit_form.file_name,
                },
                api: '/api/update-item'
            }).then(async () => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    window.scrollTo(0, 0);
                    await this.showError('Failed to Update Item')
                    this.$refs['edit-item'].show();
                } else {
                    this.error_message = '';
                    var message = 'Item successfully edited'
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.$refs.refUserListTable.refresh();
                }
            })
        },
        async deleteItem(deleteId, deleteProductCode) {
            var msg = 'Are you sure you want to delete the item ' + deleteProductCode + ' ? ';
            var message = 'Order Deleted Successfully.';

            Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                        data: {
                            id: deleteId,
                        },
                        api: "/api/delete-item",
                    })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;
                                this.showError('Failed to delete Item');
                                window.scrollTo(0, 0);
                            } else {
                                this.error_message = '';
                                var message = 'Item successfully deleted'
                                Swal.fire({
                                    position: 'center',
                                    icon: 'success',
                                    title: message,
                                    showConfirmButton: false,
                                    timer: 1500
                                })

                                this.$refs.refUserListTable.refresh();
                            }
                        });
                }
            })
        },
        async addItem() {
            return this.$store.dispatch(POST_API, {
                data: {
                    item_name: this.add_form.item_name,
                    product_code: this.add_form.product_code,
                    halal: this.add_form.halal.toLowerCase(),
                    price: (this.add_form.price) ? this.add_form.price : 0,
                    status: 'Active',
                    item_photo: this.add_form.item_photo,
                    site: this.$store.getters.currentUser.site,
                    file_name: this.add_form.file_name,
                },
                api: "/api/add-item",
            }).then(async () => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    window.scrollTo(0, 0);
                    await this.showError('Failed to add new Item');
                    this.$refs['add-item'].show();
                } else {
                    this.error_message = '';
                    var message = 'Item successfully added'
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.$refs.refUserListTable.refresh();
                }
            })
        },
        breadCrumb() {
            var item = [{
                to: { name: 'manage-order' },
                text: 'Manage Order',
            }, {
                to: null,
                text: 'Item DataBase',
                active: true
            }];
            return item;
        },
        filterTable() {
            this.$refs.refUserListTable.refresh();
        },
        resetFilter() {
            this.filter_item_status = 'Active';
            this.filter_item_name = '';
            this.filter_halal = '';
            this.filter_item_product_code = '';
            this.filterTable();
        },
        ResetData() {
            this.form.item_name = "";
            this.form.product_code = "";
            this.form.price = "";
            this.form.halal = "";
            this.form.item_photo = '';
        },
        ResetEditData() {
            this.edit_form.item_name = "";
            this.edit_form.product_code = "";
            this.edit_form.price = "";
            this.edit_form.halal = "";
            this.edit_form.item_photo = '';
            this.edit_form.id = '';
        },
        ResetAddData() {
            this.add_form.item_name = '';
            this.add_form.product_code = '';
            this.add_form.price = '';
            this.add_form.halal = '';
            this.add_form.item_photo = '';
        },
        async showError(warningTitle) {
            await Swal.fire({
                position: 'center',
                icon: 'warning',
                title: warningTitle,
                text: this.error_message,
                showConfirmButton: false,
                timer: 2000,
            }).then(() => {
                return new Promise(resolve => {
                    resolve(true);
                })
            })
        },

    },
    components: {
        BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, vSelect, BFormDatepicker, BFormSelect, BFormSelectOption, BModal, BBreadcrumb, BFormGroup, BForm, VueGoogleAutocomplete, BInputGroupPrepend, BInputGroup, BInputGroupAppend, BAlert, BFormFile, moment, BImg
    },
    mounted() {
        this.getItems();
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

#item_photo {
    width: 40%;
    height: 150px;
}

.item_photo {
    width: 60%;
    height: 400px;
}

.img_upload {
    display: grid;
    grid-template-columns: 60% 40%;
    column-gap: 10px;
    height: 400px;
}

.img_upload .edit {
    width: 100%;
    height: 400px;
}

.img_upload .edit-btn {
    height: 50px !important;
    margin: auto;
}
</style>

<style lang="scss">
.pac-container {
    z-index: 99999 !important;
    /* needs to be > z-index modal */
}

@import '@core/scss/vue/libs/vue-select.scss';
</style>